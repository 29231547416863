import React, { Component } from "react";

import {
  Table,
  Button,
  Divider,
  Popconfirm,
  Input,
  Icon,
  notification
} from "antd";

import axios from "axios";

import StudentForm from "./StudentForm";

class Student extends Component {
  state = { dataSource: [], onVisibleCreateView: false, fetching: true };

  componentDidMount = async () => {
    const response = await axios.get("user/student/");
    const dataSource = response.data;
    this.setState({ dataSource, fetching: false });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };
  handleOnAddStudent = async (course, props) => {
    const { selectedRow } = this.state;
    const newCourse = { ...selectedRow, ...course };
    this.setState({ fetching: true });

    try {
      const response = selectedRow
        ? await axios.put("user/student/", newCourse)
        : await axios.post("user/student/", newCourse);
      const index = this.state.dataSource.findIndex(
        record => record.zid === response.data.zid
      );
      props.resetFields();
      if (selectedRow) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, response.data]
          : [
              ...this.state.dataSource.slice(0, index),
              response.data,
              ...this.state.dataSource.slice(index + 1)
            ];
      this.setState({
        dataSource,
        onVisibleCreateView: false,
        fetching: false
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  handleOnDeleteStudent = async zid => {
    this.setState({ fetching: true });
    try {
      await axios.delete("user/student/", { data: { zid } });
      const dataSource = this.state.dataSource.filter(
        record => record.zid !== zid
      );

      this.setState({ dataSource, fetching: false });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  TableView = () => {
    const { dataSource, fetching } = this.state;

    const columns = [
      { title: "ZID", key: "zid", dataIndex: "zid" },
      { title: "Name", key: "name", dataIndex: "name" },
      { title: "Email", key: "email", dataIndex: "email" },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Button
              type="link"
              disabled={record.saved}
              onClick={() =>
                this.setState({
                  selectedRow: record,
                  onVisibleCreateView: true
                })
              }
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleOnDeleteStudent(record.zid)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </span>
        )
      }
    ];

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Students
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Code"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />

            <>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                disabled={!this.state.selectedRow}
                onClick={this.onCreateDuplicate}
              >
                Create Copy
              </Button>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                onClick={() => {
                  this.setState({
                    onVisibleCreateView: true,
                    selectedRow: undefined
                  });
                }}
              >
                Create New
              </Button>
            </>
          </div>
          <Table
            rowKey={record => record.zid}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={dataSource}
          />
          <StudentForm
            onAddStudent={this.handleOnAddStudent}
            onVisibleCreateView={this.state.onVisibleCreateView}
            selectedStudent={this.state.selectedRow}
            onCancel={() => this.setState({ onVisibleCreateView: false })}
          />
        </div>
      </>
    );
  };
  render() {
    return this.TableView();
  }
}

export default Student;
