import React, { Component } from "react";

import {
  Form,
  Input,
  InputNumber,
  Button,
  Col,
  Row,
  Popconfirm,
  Select
} from "antd";

class CourseFrom extends Component {
  state = { htmlPreview: "" };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log({ ...values });
        this.props.onAddcourse({ ...values });
      }
    });
  };

  componentDidMount = () => {
    if (this.props.selectedCourse) {
      this.setState({ htmlPreview: this.props.selectedCourse.description });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedCourse, terms } = this.props;
    const { htmlPreview } = this.state;
    return (
      <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Code">
              {getFieldDecorator("code", {
                initialValue: selectedCourse ? selectedCourse.code : "",
                rules: [{ required: true, message: "Please enter course Code" }]
              })(
                <Input
                  disabled={!!selectedCourse}
                  placeholder="Please enter course Code"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Term">
              {getFieldDecorator("term", {
                initialValue: selectedCourse ? selectedCourse.term : "",

                rules: [{ required: true, message: "Please enter Term" }]
              })(
                <Select optionFilterProp="name" disabled={!!selectedCourse}>
                  {terms.map(term => (
                    <Select.Option
                      key={term.id}
                      value={term.id}
                      name={term.label}
                    >
                      {term.label + " - " + term.description}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Title">
              {getFieldDecorator("title", {
                initialValue: selectedCourse ? selectedCourse.title : "",
                rules: [
                  { required: true, message: "Please enter course Title" }
                ]
              })(<Input placeholder="Please enter course Title" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Moodle ID">
              {getFieldDecorator("moodle_id", {
                initialValue: selectedCourse ? selectedCourse.moodle_id : null,
                rules: []
              })(
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="please enter course Id "
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={18}>
            <Form.Item label="Description">
              {getFieldDecorator("description", {
                initialValue: selectedCourse ? selectedCourse.description : "",
                rules: []
              })(
                <Input.TextArea
                  rows={10}
                  onChange={e => {
                    this.setState({ htmlPreview: e.target.value });
                  }}
                  placeholder="please enter  description"
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={18}>
            Preview
            <div
              style={{
                height: "200px",
                border: "2px black solid",
                marginBottom: 20,
                marginTop: 5,
                padding: 10
              }}
              dangerouslySetInnerHTML={{
                __html: htmlPreview
              }}
            ></div>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ offset: 16 }}>
          <Popconfirm
            title="Sure to cancel?"
            onConfirm={() => this.props.onCancel()}
          >
            <Button style={{ marginRight: "10px" }} type="danger">
              Cancel
            </Button>
          </Popconfirm>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "register" })(CourseFrom);
