import React, { Component } from "react";

import { Button, Layout, Divider, Collapse, Progress, Alert, Icon } from "antd";

class CourseDetailsView extends Component {
  state = {};
  render() {
    const {
      code,
      title,
      description,
      course_id,
      term,
      assessments
    } = this.props.course;

    const completed = assessments.filter(
      assessment => assessment.status === "COMPLETED"
    ).length;

    const percentage = Math.floor((completed * 100) / assessments.length);

    return (
      <>
        <Divider />

        <Layout style={{ padding: 25, background: "#fff" }}>
          <Collapse
            bordered={false}
            defaultActiveKey={["detail-view"]}
            className="detail-view"
          >
            <Collapse.Panel
              header={<h2>{`${code} ${title} (${term})`}</h2>}
              key="detail-view"
            >
              <h3>Description</h3>
              <div
                style={{
                  marginBottom: 20,
                  marginTop: 5,
                  padding: 10
                }}
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              ></div>

              <h3 style={{ marginTop: 18 }}>Moodle tasks</h3>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 18 }}>
                  <Progress type="circle" percent={percentage} />
                </div>
                <div style={{ flex: 1 }}>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {assessments.map(assessment => (
                      <li key={assessment.id}>
                        <Icon
                          type={
                            assessment.status === "COMPLETED"
                              ? "check-square"
                              : "minus-square"
                          }
                          style={{ marginRight: 6 }}
                        />
                        {assessment.title}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div style={{ marginTop: 16, display: "flex" }}>
                <Alert
                  message="Your progression may take up to 24 hours to update."
                  type="warning"
                  showIcon
                />
              </div>
              <Button
                style={{ marginTop: 12 }}
                disabled={!course_id}
                onClick={() =>
                  window.open(
                    `https://moodle.telt.unsw.edu.au/course/view.php?id=${course_id}`,
                    "_blank"
                  )
                }
              >
                Click here to go to the course page →
              </Button>
            </Collapse.Panel>
          </Collapse>
        </Layout>
        <Divider />
      </>
    );
  }
}

export default CourseDetailsView;
