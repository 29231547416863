import React, { Component, Suspense } from "react";
import { Switch, Route, NavLink, Redirect, withRouter } from "react-router-dom";
import { Button, Layout, Menu } from "antd";
import { authenticatedApplication } from "react-msal-jwt";
import { LandingPage } from "login-landing-page";
import axios from "axios";
import preval from "preval.macro";

import Forbidden from "./error/Forbidden";
import PageNotFound from "../src/error/404";

import Dashboard from "./components/learner/Dashboard";
import Course from "./components/admin/course/Courses";
import Terms from "./components/admin/term/Terms";
import Student from "./components/admin/student/Students";
import Enrollment from "./components/admin/Enrollment";
import Stage from "./components/admin/Stage";
import FileUpload from "./components/admin/FileUpload";

import LTILogin from "./components/LTILogin";

import AppContext from "./AppContext";

import "./App.css";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("access")}`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      context_id: sessionStorage.getItem("context_id"),
      isAdmin: props.isAdmin || sessionStorage.getItem("isAdmin") === "true",
      firstName: props.first_name || sessionStorage.getItem("first_name")
    };

    // If mounting the component on /error or /forbidden routes,
    // then redirect to the root route
    if (["/error", "/forbidden"].includes(props.location.pathname))
      props.history.replace("/");

    // Intercept requests to detect whether the access token is still valid
    axios.interceptors.request.use(
      async config => {
        const {
          access: hasAccessToken,
          refresh: hasRefreshToken
        } = props.isTokenExpired();

        // If the access token is invalid, and we are not interacting with auth endpoints,
        // then renew the access token
        if (
          !hasAccessToken &&
          !["auth/login/", "auth/refresh/", "auth/error/"].includes(config.url)
        ) {
          if (hasRefreshToken) {
            const accessToken = await props.refreshAccessToken();
            config.headers.common["Authorization"] = `Bearer ${accessToken}`;
          } else {
            props.throwTokenError();
          }
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  render() {
    const { getAzureToken, logout, location } = this.props;
    const { isAdmin, firstName, context_id } = this.state;

    const routeKey = location.pathname.split("/")[1];

    return (
      <AppContext.Provider
        value={{ getAzureToken, isAdmin, logout, firstName, context_id }}
      >
        {!isAdmin ? (
          <Dashboard />
        ) : (
          <Layout style={{ height: "100%", backgroundColor: "rgb(59,16,103)" }}>
            <Layout.Sider>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  backgroundColor: "rgb(59,16,103)"
                }}
              >
                <img
                  className="logo"
                  src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
                  alt="UNSW Logo"
                  style={{ width: "80%", margin: 20 }}
                />

                <div style={{ flex: 1 }}>
                  <Menu
                    theme="dark"
                    mode="inline"
                    defaultOpenKeys={["manage"]}
                    selectedKeys={[routeKey]}
                    style={{
                      backgroundColor: "rgb(59,16,103)"
                    }}
                  >
                    <Menu.SubMenu title="Manage Resources" key="manage">
                      <Menu.Item key="course">
                        <NavLink to="/course">Courses</NavLink>
                      </Menu.Item>
                      <Menu.Item key="student">
                        <NavLink to="/student">Students</NavLink>
                      </Menu.Item>
                      <Menu.Item key="enrollment">
                        <NavLink to="/enrollment">Enrollments</NavLink>
                      </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu title="Advanced" key="advance">
                      <Menu.Item key="stage">
                        <NavLink to="/stage">Stages</NavLink>
                      </Menu.Item>
                      <Menu.Item key="term">
                        <NavLink to="/term">Terms</NavLink>
                      </Menu.Item>
                      <Menu.Item key="upload">
                        <NavLink to="/upload">Import</NavLink>
                      </Menu.Item>
                    </Menu.SubMenu>
                  </Menu>
                </div>

                <div
                  style={{
                    textAlign: "center",
                    color: "rgba(160, 160, 160, 0.75)",
                    margin: "20px 0"
                  }}
                >
                  {`Build date: ${preval`
                const moment = require("moment");
                module.exports = moment().format("DD/MM/YYYY");
              `}`}
                </div>
              </div>
            </Layout.Sider>

            <Layout>
              <Layout.Header
                style={{
                  background: "rgb(59, 16, 103)",
                  padding: "0 2px",
                  margin: 10
                }}
              >
                <h1
                  style={{
                    marginLeft: 20,
                    textAlign: "center",
                    color: "white"
                  }}
                >
                  Practical Legal Training
                  <Button
                    type="warning"
                    icon="poweroff"
                    onClick={logout}
                    style={{
                      float: "right",
                      marginTop: "15px",
                      marginRight: "1%"
                    }}
                  >
                    Logout
                  </Button>
                  <div
                    style={{
                      float: "right",
                      marginTop: "15px",
                      marginRight: "2%"
                    }}
                  />
                </h1>
              </Layout.Header>

              <Layout.Content
                style={{
                  margin: "1px 16px 0",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%"
                }}
              >
                <div style={{ padding: 24, background: "#fff", flex: "1" }}>
                  <Switch>
                    <Redirect exact from="/" to="/course" />

                    <Suspense fallback={<div>Loading...</div>}>
                      <Route path="/course" component={Course} />
                      <Route path="/student" component={Student} />
                      <Route path="/enrollment" component={Enrollment} />
                      <Route path="/stage" component={Stage} />
                      <Route path="/term" component={Terms} />
                      <Route path="/upload" component={FileUpload} />
                    </Suspense>
                  </Switch>
                </div>
              </Layout.Content>
            </Layout>
          </Layout>
        )}
      </AppContext.Provider>
    );
  }
}

export default () => (
  <Switch>
    <Route path="/forbidden" component={Forbidden} />
    <Route path="/404" component={PageNotFound} />
    <Route path="/lti/:lti" component={LTILogin} />

    <Route path="/" component={loginPage} />
  </Switch>
);
const loginPage = authenticatedApplication({
  landingPage: (
    <LandingPage
      title="Practical Legal Training"
      background="https://cdn.teaching.unsw.edu.au/161006_UNSW_016.jpg"
      logo={
        <a href="https://www.unsw.edu.au/">
          <img
            src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
            alt="UNSW Logo"
          />
        </a>
      }
      footerItems={[
        <a href="mailto:contact.pvce@unsw.edu.au">Contact us</a>,
        <a href="https://www.unsw.edu.au/privacy">Privacy Policy</a>,
        <a href="https://www.unsw.edu.au/copyright-disclaimer">
          Copyright &amp; Disclaimer
        </a>,
        <span style={{ color: "rgba(117, 117, 117, 0.5)" }}>
          {`Build date: ${preval`
        const moment = require("moment");
        module.exports = moment().format("DD/MM/YYYY");
      `}`}
        </span>
      ]}
    />
  ),
  msalConfig: {
    auth: {
      clientId: process.env.REACT_APP_AZURE_APP_ID,
      authority: process.env.REACT_APP_AZURE_AUTHORITY,
      redirectUri: process.env.REACT_APP_FRONTEND_URL
    }
  },
  onAuthSuccess: async (azureIdToken, azureAccessToken) => {
    const headers = {
      "Content-Type": "application/json; charset=utf8",
      Authorization: "Token " + azureIdToken
    };
    const response = await axios.post(
      "auth/login/",
      { accessToken: azureAccessToken },
      { headers }
    );

    const data = response.data;
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;
    sessionStorage.setItem("isAdmin", data.is_admin);
    sessionStorage.setItem("first_name", data.first_name);

    return {
      accessToken: data.access,
      refreshToken: data.refresh,
      extras: {
        isAdmin: data.isAdmin,
        first_name: data.first_name
      }
    };
  },
  onAuthError: error => {
    const { errorCode } = error;

    if (errorCode === "user_cancelled" || errorCode === "access_denied")
      return { type: "warning", message: "Login popup was closed." };
    else if (errorCode === "login_progress_error")
      return { type: "warning", message: "Login popup is already open." };
    else if (errorCode === "popup_window_error")
      return {
        type: "warning",
        message:
          "Error opening popup window. This can happen if you are using IE or if popups are blocked in the browser."
      };
    else if (error.message === "Network Error")
      return {
        type: "error",
        message: (
          <>
            Failed to communicate with the server. If the issue persists, please{" "}
            <a href="mailto:contact.pvce@unsw.edu.au">contact support</a>.
          </>
        )
      };

    const payload = {
      userAgent: window.navigator.userAgent,
      name: error.name,
      code: errorCode,
      message: error.message,
      stack: error.stack.toString().split("\n")
    };
    const headers = {
      "Content-Type": "application/json; charset=utf8",
      common: { Authorization: null }
    };
    axios.post("auth/error/", payload, { headers });

    return {
      type: "error",
      message: (
        <>
          An issue occurred while logging you in. Please try again, ensuring
          that you use <strong>{`<Your zID>`}@ad.unsw.edu.au</strong> to log in.
          If the issue persists, please{" "}
          <a href="mailto:contact.pvce@unsw.edu.au">contact support</a>.
        </>
      )
    };
  },
  refreshAccess: async refresh => {
    const response = await axios.post("auth/refresh/", { refresh });

    const data = response.data;
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;

    return data.access;
  },
  tokenCheckFrequency: 2
})(withRouter(props => <App {...props} />));
