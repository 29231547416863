import React, { Component, Suspense, lazy } from "react";
import { NavLink } from "react-router-dom";
import { Button, Layout, Row, Col } from "antd";
import axios from "axios";

import preval from "preval.macro";

import Summary from "./programSummary/Summary";
import NotificationPanel from "./NotificationPanel";

import "../dashboard.css";

import AppContext from "../../AppContext";
import CourseDetailsView from "./CourseDetailsView";
import ProgramContacts from "./contacts/ProgramContacts";
import Deadline from "./deadlines/Deadline";

const ProgramTracker = lazy(() => import("./tracker/ProgramTracker"));

class Dashboard extends Component {
  static contextType = AppContext;

  state = { stages: [], allCourses: [] };

  componentDidMount = async () => {
    const stagesResponse = await axios.get("admin/stage/detail/");

    const selectedCourse = stagesResponse.data
      .map(stage => [...stage.courses])
      .flat([1])
      .find(course => course.moodle_id === parseInt(this.context.context_id));

    const selected = selectedCourse ? selectedCourse.id : null;

    this.setState({
      selected,
      stages: stagesResponse.data
    });
  };

  render() {
    const { selected, stages } = this.state;
    const { logout } = this.context;

    const allCourses = stages.map(stage => [...stage.courses]).flat([1]);

    return (
      <Layout style={{ minHeight: "100%" }}>
        <Layout.Header
          style={{
            display: "flex",
            alignItems: "center",
            background: "rgb(115, 32, 122)"
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              height: "100%"
            }}
          >
            <NavLink to="/" style={{ height: "100%" }}>
              <img
                src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
                alt="UNSW logo"
                style={{
                  maxHeight: "100%",
                  width: "auto",
                  padding: "10px 0",
                  marginRight: 25
                }}
              />
            </NavLink>

            <h1
              style={{
                fontFamily: "Sommet",
                color: "white",
                margin: 0,
                lineHeight: 1
              }}
            >
              Practical Legal Training
            </h1>
          </div>

          <Button icon="logout" onClick={logout}>
            Log out
          </Button>
        </Layout.Header>

        <Layout.Content
          style={{
            padding: "20px",
            background:
              "url(https://cdn.teaching.unsw.edu.au/PLT/opera_house.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        >
          <Row type="flex" justify="space-around" gutter={[16, 16]}>
            <Col
              xs={24}
              md={24}
              lg={24}
              xl={9}
              className="block-container"
              style={{
                background: "rgba(115, 41, 122,0.38)"
              }}
            >
              <div>
                <Suspense fallback={<div>Loading...</div>}>
                  <Row>
                    <div
                      className="header-text-container"
                      style={{
                        background: "rgba(113, 39, 112,0.86)"
                      }}
                    >
                      <span className="header-text">Program Tracker</span>
                    </div>
                  </Row>
                  <ProgramTracker
                    stages={this.state.stages}
                    onSelect={id => this.setState({ selected: id })}
                  />
                </Suspense>
              </div>
            </Col>

            <Col
              xs={24}
              md={24}
              lg={24}
              xl={14}
              className="block-container"
              style={{
                background: "rgba(255, 255, 255,0.38)"
              }}
            >
              <div>
                <Row>
                  <Col>
                    <div
                      className="header-text-container"
                      style={{
                        background: "rgba(32, 70, 81,0.86)"
                      }}
                    >
                      <span className="header-text">Program Dashboard</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={24} xl={24} xxl={0}>
                    {selected && (
                      <CourseDetailsView
                        course={allCourses.find(
                          course => course.id === selected
                        )}
                      />
                    )}
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={24} lg={24} xl={17}>
                    <Row
                      style={{
                        margin: "10px"
                      }}
                    >
                      <Summary stages={stages} />
                    </Row>
                    <Row
                      style={{
                        margin: "20px"
                      }}
                    >
                      <Deadline stages={stages} />
                    </Row>
                  </Col>

                  <Col xs={24} md={24} lg={24} xl={7}>
                    <NotificationPanel />
                  </Col>
                </Row>
                <Row
                  style={{
                    margin: "10px"
                  }}
                >
                  <Col xs={0} md={0} lg={0} xl={0} xxl={24}>
                    {selected && (
                      <CourseDetailsView
                        course={allCourses.find(
                          course => course.id === selected
                        )}
                      />
                    )}
                  </Col>
                  <Col xs={24} md={23} lg={23} xl={24} xxl={24}>
                    <ProgramContacts
                      course={allCourses.find(course => course.id === selected)}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Layout.Content>
        <Layout.Footer
          className="footer"
          style={{ background: "rgb(59,16,103)" }}
        >
          <ul>
            <li>
              <a href="mailto:contact.pvce@unsw.edu.au">Contact us</a>
            </li>
            <li>
              <a href="https://www.unsw.edu.au/privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="https://www.unsw.edu.au/copyright-disclaimer">
                Copyright &amp; Disclaimer
              </a>
            </li>
            <li>
              <div>Office of the Pro Vice-Chancellor (Education)</div>
              <div>UNSW Sydney NSW 2052 Australia</div>
              <div>Authorised by: Pro Vice-Chancellor (E), UNSW</div>
              <div style={{ color: "rgba(117, 117, 117, 0.5)" }}>
                {`Build date: ${preval`
                    const moment = require("moment");
                    module.exports = moment().format("DD/MM/YYYY");
                  `}`}
              </div>
            </li>
          </ul>
        </Layout.Footer>
      </Layout>
    );
  }
}

export default Dashboard;
