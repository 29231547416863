import React, { Component } from "react";
import { List, Row, Col, Divider, Avatar } from "antd";

class Deadline extends Component {
  state = {};
  render() {
    const data = [
      {
        title: "Ant Design Title 1"
      },
      {
        title: "Ant Design Title 2"
      },
      {
        title: "Ant Design Title 3"
      },
      {
        title: "Ant Design Title 4"
      },
      {
        title: "Ant Design Title 2"
      },
      {
        title: "Ant Design Title 3"
      },
      {
        title: "Ant Design Title 4"
      }
    ];
    return (
      <Row
        type="flex"
        gutter={16}
        style={{ background: "white", padding: "10px" }}
      >
        <Col span={24}>
          <div className="sub-header-text-container">
            <span className="sub-header-text">Your Deadlines</span>
          </div>
          <div
            style={{
              borderRadius: "5px",
              background: "white"
            }}
          >
            <div className="deadline-infinite-container">
              <List
                itemLayout="horizontal"
                style={{ marginTop: "20px" }}
                dataSource={data}
                renderItem={item => (
                  <div>
                    <div>
                      <span>
                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                      </span>
                      <span
                        style={{
                          padding: "2px",
                          color: "#73207a",
                          alignItems: "center"
                        }}
                      >
                        Deadline - {item.title}
                      </span>
                      <span
                        style={{
                          padding: "2px",
                          color: "#73207a",
                          alignItems: "center",
                          float: "right"
                        }}
                      >
                        12/02/2020
                      </span>
                    </div>

                    <Divider className="custom-divider" />
                  </div>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default Deadline;
