import React from "react";
import { Row, Col } from "antd";

function SummaryTile(props) {
  const { title, value } = props;
  return (
    <Col
      xs={24}
      md={24}
      lg={24}
      xl={24}
      xxl={12}
      style={{ marginBottom: "10px" }}
    >
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        style={{
          padding: "10px",
          fontSize: "20px",
          background: "#227689",
          color: "white"
        }}
      >
        <Col xs={21} md={21} lg={20} xl={16} style={{ textAlign: "center" }}>
          <Row>
            <Col style={{ fontSize: "120%", fontWeight: "bold" }} span={24}>
              {title}
            </Col>
            <Col span={24}>
              <img style={{ width: "50%" }} src={props.icon} alt={props.alt} />
            </Col>
          </Row>
        </Col>

        <Col
          offset={1}
          xs={2}
          md={2}
          lg={3}
          xl={7}
          style={{ fontSize: "300%" }}
        >
          {value}
        </Col>
      </Row>
    </Col>
  );
}

export default SummaryTile;
