import React, { Component } from "react";
import { List, Spin, Tag } from "antd";

import axios from "axios";

import xml2js from "xml2js";
class NotificationPanel extends Component {
  state = { data: [], loading: true, hasMore: true };

  componentDidMount = () => {
    this.fetchData(res => {
      this.setState({
        data: res,
        loading: false
      });
    });
    setInterval(() => {
      this.fetchData(res => {
        this.setState({
          data: res,
          loading: false
        });
      });
    }, 10000 * 6 * 10);
  };

  fetchData = async callback => {
    this.setState({ loading: true });
    const urlList = [
      {
        code: "PLTX1020",
        rss:
          "https://moodle.telt.unsw.edu.au/rss/file.php/5321827/f264457334b0af3b5430dba6f6a7fd52/mod_forum/193649/rss.xml"
      },
      {
        code: "PLTX1040",
        rss:
          "https://moodle.telt.unsw.edu.au/rss/file.php/5321827/f264457334b0af3b5430dba6f6a7fd52/mod_forum/193649/rss.xml"
      }
    ];
    let data1 = [];
    const parser = new xml2js.Parser();
    for (var i = 0; i < urlList.length; i++) {
      const response = await axios.get(
        "https://4gzl843zl6.execute-api.us-east-1.amazonaws.com/dev/cats/" +
          urlList[i].rss,
        {
          headers: {
            "Cache-Control": "no-cache"
          }
        }
      );

      await parser.parseString(response.data, (err, result) => {
        const { item, image } = result.rss.channel[0];

        const data = item.map(item => ({
          code: urlList[i].code,
          title: item.title[0],
          image: image[0]["url"][0],
          pubDate: new Date(item.pubDate[0]),
          description: item.description[0].match(
            "<s*span[^>]*>(.*?)<s*/s*span>"
          )[1]
        }));
        data1.push(...data);
      });
    }

    data1.sort((a, b) => a.pubDate - b.pubDate);

    callback(data1);
  };

  render() {
    const { data } = this.state;

    return (
      <>
        <div
          className="sub-header-text-container"
          style={{ marginTop: "10px" }}
        >
          <span className="sub-header-text">Announcements</span>
        </div>
        <div
          style={{
            border: "1px black solid",
            padding: "10px",
            background: "white"
          }}
        >
          <div
            style={{
              height: "20vh",
              marginBottom: "10px",
              background:
                "url(https://cdn.teaching.unsw.edu.au/PLT/UNSW-Law-building-lw.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}
          ></div>
          <div className="demo-infinite-container">
            <Spin
              spinning={this.state.loading && this.state.hasMore}
              tip="Loading..."
            >
              <List
                itemLayout="horizontal"
                dataSource={data}
                disabled={true}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <div style={{ padding: "2px", color: "#73207a" }}>
                          <span
                            style={{
                              padding: "2px",
                              color: "#73207a",
                              alignItems: "center"
                            }}
                          >
                            {item.title}
                          </span>
                          <span
                            style={{
                              alignItems: "center",
                              float: "right"
                            }}
                          >
                            <Tag color="blue">{item.code}</Tag>{" "}
                            <img src={item.image} style={{ width: "30px" }} />
                          </span>
                        </div>
                      }
                      description={
                        <div style={{ padding: "2px" }}>{item.description}</div>
                      }
                    />
                  </List.Item>
                )}
              ></List>
            </Spin>
          </div>
        </div>
      </>
    );
  }
}

export default NotificationPanel;
