import React, { Component } from "react";

import {
  Table,
  Input,
  Icon,
  Button,
  Divider,
  Popconfirm,
  Select,
  notification
} from "antd";

import uuid from "uuid";
import axios from "axios";
import Highlighter from "react-highlight-words";

class Enrollment extends Component {
  state = {
    dataSource: [],
    terms: [],
    status: [
      { id: "ENROLLED", label: "Enrolled" },
      { id: "UNENROLLED", label: "Unenrolled" },
      { id: "COMPLETED", label: "Completed" }
    ],
    courses: [],
    students: [],
    fetching: true
  };

  componentDidMount = async () => {
    const courseResponse = await axios.get("course/");
    const studentResponse = await axios.get("user/student/");
    const enrollmentResponse = await axios.get("course/enrollment/");
    const termResponse = await axios.get("admin/term/");

    this.setState({
      courses: courseResponse.data,
      students: studentResponse.data,
      terms: termResponse.data,
      dataSource: enrollmentResponse.data.map(enrollment => ({
        ...enrollment,
        saved: true
      })),
      fetching: false
    });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  saveRow = async entry => {
    this.setState({ fetching: true });
    try {
      const response = entry.temp
        ? await axios.post("course/enrollment/", entry)
        : await axios.put("course/enrollment/", entry);
      const index = this.state.dataSource.findIndex(rec => rec.id === entry.id);

      const newEntry = {
        ...response.data,
        saved: true
      };

      const dataSource = [
        ...this.state.dataSource.slice(0, index),
        { ...newEntry },
        ...this.state.dataSource.slice(index + 1)
      ];
      this.setState({ dataSource, fetching: false });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  deleteRow = async id => {
    this.setState({ fetching: true });
    try {
      const dataSource = this.state.dataSource.filter(x => x.id !== id);

      await axios.delete("course/enrollment/", { data: { id } });
      this.setState({ dataSource, fetching: false });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  addRow = () => {
    const newEntry = {
      id: uuid.v4(),
      status: "ENROLLED",
      term: 1,
      saved: true,
      temp: true
    };
    const dataSource = [...this.state.dataSource, newEntry];
    this.setState({ dataSource });
  };

  searchResult = (dataSource, text) => {
    const { courses, students } = this.state;

    if (!text) return dataSource;
    const selectedCourses = courses
      .filter(
        course =>
          course.code.toLowerCase().includes(text) ||
          course.title.toLowerCase().includes(text)
      )
      .map(course => Object.values(course)[0]);

    const selectedStudents = students
      .filter(
        student =>
          student.zid.toLowerCase().includes(text) ||
          student.name.toLowerCase().includes(text)
      )
      .map(course => Object.values(course)[0]);

    const newData = dataSource.filter(
      x =>
        selectedCourses.includes(x.course) ||
        selectedStudents.includes(x.student)
    );

    return newData;
  };

  onChangeEntry = (value, record, type) => {
    const index = this.state.dataSource.findIndex(rec => rec.id === record);
    const entry = {
      ...this.state.dataSource[index],
      [type]: value
    };
    const saved = !(entry.course && entry.student);

    const dataSource = [
      ...this.state.dataSource.slice(0, index),
      { ...entry, saved },
      ...this.state.dataSource.slice(index + 1)
    ];
    this.setState({ dataSource });
  };

  render() {
    const {
      dataSource,
      courses,
      students,
      terms,
      fetching,
      searchText,
      status
    } = this.state;
    const columns = [
      {
        title: "Student",
        key: "student",
        dataIndex: "student",
        width: "20%",
        render: (text, record) => (
          <span>
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              placeholder="Search for a course by code, name"
              optionFilterProp="name"
              value={text}
              onChange={val => this.onChangeEntry(val, record.id, "student")}
            >
              {students.map(student => (
                <Select.Option
                  key={student.zid}
                  value={student.zid}
                  name={student.zid + " " + student.name}
                >
                  <Highlighter
                    highlightClassName="YourHighlightClass"
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={student.zid + " - " + student.name}
                  />
                </Select.Option>
              ))}
            </Select>
          </span>
        )
      },
      {
        title: "Course",
        key: "course",
        dataIndex: "course",
        width: "20%",
        render: (text, record) => (
          <span>
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              placeholder="Search for a student"
              optionFilterProp="name"
              onChange={val => this.onChangeEntry(val, record.id, "course")}
              value={text}
            >
              {courses.map(course => (
                <Select.Option
                  key={course.id}
                  value={course.id}
                  name={course.code + " " + course.title}
                >
                  <Highlighter
                    highlightClassName="YourHighlightClass"
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={course.code + " - " + course.title}
                  />
                </Select.Option>
              ))}
            </Select>
          </span>
        )
      },
      {
        title: "Term",
        key: "term",
        dataIndex: "term",
        width: "10%",
        render: (text, record) => (
          <span>
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              placeholder="Search for a student"
              optionFilterProp="name"
              onChange={val => this.onChangeEntry(val, record.id, "term")}
              value={text}
            >
              {terms.map(term => (
                <Select.Option key={term.id} value={term.id} name={term.label}>
                  {term.label}
                </Select.Option>
              ))}
            </Select>
          </span>
        )
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        width: "5%",
        render: (text, record) => (
          <span>
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              placeholder="Search for a student"
              optionFilterProp="name"
              onChange={val => this.onChangeEntry(val, record.id, "status")}
              value={text}
            >
              {status.map(term => (
                <Select.Option key={term.id} value={term.id} name={term.label}>
                  {term.label}
                </Select.Option>
              ))}
            </Select>
          </span>
        )
      },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Button
              type="link"
              disabled={record.saved}
              onClick={() => this.saveRow(record)}
            >
              Save
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.deleteRow(record.id)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Students
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Course, Student"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />

            <>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                disabled={!this.state.selectedRow}
                onClick={this.onCreateDuplicate}
              >
                Save All
              </Button>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                onClick={this.addRow}
              >
                Add New
              </Button>
            </>
          </div>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchResult(dataSource, searchText)}
          />
        </div>
      </>
    );
  }
}

export default Enrollment;
