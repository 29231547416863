import React, { Component } from "react";

import axios from "axios";

class LTILogin extends Component {
  state = {};

  componentDidMount = async () => {
    const tkn = this.props.match.params.lti;

    if (tkn) {
      try {
        const response = await axios.post(
          "auth/lti_validation/",
          { token: tkn },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: null
            }
          }
        );
        const data = response.data;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access}`;
        sessionStorage.setItem("context_id", data.context_id);
        sessionStorage.setItem("isAdmin", data.is_admin);
        sessionStorage.setItem("first_name", data.first_name);
        sessionStorage.setItem("access", data.access);
        sessionStorage.setItem("refresh", data.refresh);
        this.props.history.push("/");
      } catch {
        this.props.history.push("/forbidden");
      }
    } else {
      this.props.history.push("/");
    }
  };
  render() {
    return (
      <div
        style={{
          alignItems: "center",
          width: "100%",
          height: "100%",
          background: "rgb(59,16,103)"
        }}
      ></div>
    );
  }
}

export default LTILogin;
