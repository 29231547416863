import React, { Component } from "react";

import {
  Table,
  Button,
  Divider,
  Popconfirm,
  Input,
  Icon,
  notification
} from "antd";

import axios from "axios";

import CourseForm from "./CourseForm";

class Course extends Component {
  state = { dataSource: [], onVisibleCreateView: false, fetching: true };

  componentDidMount = async () => {
    const response = await axios.get("course/");
    const termResponse = await axios.get("admin/term/");
    const dataSource = response.data;

    console.log(dataSource, termResponse.data);
    this.setState({ dataSource, terms: termResponse.data, fetching: false });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };
  handleOnAddCourse = async course => {
    const { selectedRow } = this.state;
    this.setState({ fetching: true });
    const newCourse = { ...selectedRow, ...course };

    try {
      const response = selectedRow
        ? await axios.put("course/", newCourse)
        : await axios.post("course/", newCourse);
      const index = this.state.dataSource.findIndex(
        record => record.id === response.data.id
      );

      if (selectedRow) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, response.data]
          : [
              ...this.state.dataSource.slice(0, index),
              response.data,
              ...this.state.dataSource.slice(index + 1)
            ];
      this.setState({
        dataSource,
        onVisibleCreateView: false,
        fetching: false
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      this.setState({ fetching: false });
      console.log(error);
    }
  };

  handleOnDeleteCourse = async id => {
    try {
      await axios.delete("course/", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        record => record.id !== id
      );

      this.setState({ dataSource });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
    }
  };

  TableView = () => {
    const { dataSource, fetching } = this.state;

    const columns = [
      { title: "Code", key: "code", dataIndex: "code" },
      { title: "Title", key: "title", dataIndex: "title" },
      { title: "Term", key: "term", dataIndex: "term" },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Button
              type="link"
              disabled={record.saved}
              onClick={() =>
                this.setState({
                  selectedRow: record,
                  onVisibleCreateView: true
                })
              }
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleOnDeleteCourse(record.id)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </span>
        )
      }
    ];

    const expandedRow = (record, id) => {
      const columns = [
        { title: "Task Title", key: "display_name", dataIndex: "display_name" }
        // {
        //   title: "Assessment Type",
        //   key: "assessment_type",
        //   dataIndex: "assessment_type"
        // },
        // { title: "Assessment Weight", key: "weight", dataIndex: "weight" }
      ];

      return (
        <Table
          dataSource={record.tasks}
          columns={columns}
          rowKey={record => record.id}
        />
      );
    };

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Courses
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Code"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />

            <>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                disabled={!this.state.selectedRow}
                onClick={this.onCreateDuplicate}
              >
                Create Copy
              </Button>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                onClick={() => {
                  this.setState({
                    onVisibleCreateView: true,
                    selectedRow: undefined
                  });
                }}
              >
                Create New
              </Button>
            </>
          </div>
          <Table
            rowKey={record => record.id}
            bordered
            columns={columns}
            dataSource={dataSource}
            loading={fetching}
            expandedRowRender={expandedRow}
          />
        </div>
      </>
    );
  };
  render() {
    const { onVisibleCreateView, terms } = this.state;
    return onVisibleCreateView ? (
      <CourseForm
        terms={terms}
        onAddcourse={this.handleOnAddCourse}
        onVisibleCreateView={this.state.onVisibleCreateView}
        selectedCourse={this.state.selectedRow}
        onCancel={() => this.setState({ onVisibleCreateView: false })}
      />
    ) : (
      this.TableView()
    );
  }
}

export default Course;
