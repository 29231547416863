import React, { Component } from "react";

import { Card } from "antd";
const { Meta } = Card;
class ProgramContactTile extends Component {
  state = {};
  render() {
    return (
      <Card
        hoverable
        style={{ width: 180, margin: "10px" }}
        cover={
          <img
            style={{ border: "black 2px solid", borderRadius: "50%" }}
            alt={this.props.profile.name}
            src={this.props.profile.image}
          />
        }
      >
        <Meta
          title={this.props.profile.name}
          description={this.props.profile.title}
        />
      </Card>
    );
  }
}

export default ProgramContactTile;
