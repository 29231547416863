import React, { Component } from "react";

import { Row } from "antd";
import SummaryTile from "./SummaryTile";

import lawyear from "../../../img/icons/noun_Lawyer.png";
import pie from "../../../img/icons/noun_Pie.png";
import research from "../../../img/icons/noun_Research.png";
import video from "../../../img/icons/noun_Video Conference.png";

class Summary extends Component {
  state = {};
  render() {
    const numRequiredCourses = this.props.stages
      .map(stage => stage.required_num_of_courses)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const allCourses = this.props.stages
      .map(stage => [...stage.courses])
      .flat(1);

    const completed = allCourses.filter(course => course.status === "COMPLETED")
      .length;
    const inProgress = allCourses.filter(course => course.status === "ENROLLED")
      .length;
    const future = numRequiredCourses - completed - inProgress;

    //const  future=
    return (
      <div>
        <div className="sub-header-text-container">
          <span className="sub-header-text">Your Progress</span>
        </div>
        <Row gutter={16}>
          <SummaryTile
            icon={lawyear}
            alt="future icon"
            title={"Future"}
            value={future}
          />
          <SummaryTile
            icon={pie}
            alt="in progress icon"
            title={"In Progress"}
            value={inProgress}
          />
          <SummaryTile
            icon={research}
            alt="completed icon"
            title={"Completed"}
            value={completed}
          />
          <SummaryTile
            icon={video}
            alt="placeholder icon"
            title={"Placeholder"}
            value={3}
          />
        </Row>
      </div>
    );
  }
}

export default Summary;
