import React, { Component } from "react";

import {
  Upload,
  Button,
  Icon,
  notification,
  Table,
  Select,
  Input,
  Alert
} from "antd";
import axios from "axios";

const { TextArea } = Input;

class FileUpload extends Component {
  state = {
    fileList: [],
    uploading: false,
    verifying: false,
    fetched: false,
    isVerified: false,
    successLog: [],
    errorLog: []
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  handleOnClickUpload = async () => {
    const { fileList, verifiedFileList } = this.state;

    this.setState({
      uploading: true
    });

    var result = {};
    for (var i = 0; i < verifiedFileList.length; i++) {
      const columns = verifiedFileList[i].columns;
      let columnsList = {};
      for (var j = 0; j < columns.length; j++) {
        const { header, ...data } = columns[j];
        columnsList[columns[j].header] = {
          ...data
        };
      }

      result[verifiedFileList[i].name] = columnsList;
    }

    const formData = new FormData();
    fileList.forEach(file => {
      formData.append("files", file);
    });

    formData.append("tasks", JSON.stringify(result));

    try {
      const response = await axios.post("admin/upload/confirm/", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      });

      const { error_log, success_log } = response.data;
      this.setState({
        errorLog: error_log,
        successLog: success_log,
        verifiedFileList: [],
        fileList: [],
        uploading: false,
        verifying: false,
        fetched: false,
        isVerified: false
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      this.setState({
        verifiedFileList: [],
        fileList: [],
        fetched: true,
        uploading: false
      });
    }
  };

  handleOnClickVerify = async () => {
    const { fileList } = this.state;
    this.setState({
      verifying: true
    });

    const formData = new FormData();
    fileList.forEach(file => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post("admin/upload/verify/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      console.log(response.data);

      const { error_log, verified_list } = response.data;
      this.setState({
        errorLog: error_log,
        verifiedFileList: verified_list,
        fetched: true,
        successLog: [],
        verifying: false,
        isVerified: true
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      this.setState({
        verifying: false
      });
    }
  };

  onActionChange = (fileIndex, rowIndex, attribute, value) => {
    const itemIndex = this.state.verifiedFileList[fileIndex].columns.findIndex(
      x => x.id === rowIndex
    );
    const item = {
      ...this.state.verifiedFileList[fileIndex].columns[itemIndex],
      [attribute]: value
    };

    const newColumns = [
      ...this.state.verifiedFileList[fileIndex].columns.slice(0, itemIndex),
      item,
      ...this.state.verifiedFileList[fileIndex].columns.slice(itemIndex + 1)
    ];

    const verifiedFileList = [
      ...this.state.verifiedFileList.slice(0, fileIndex),
      { ...this.state.verifiedFileList[fileIndex], columns: newColumns },
      ...this.state.verifiedFileList.slice(fileIndex + 1)
    ];

    this.setState({ verifiedFileList });
  };

  tableView = (file, index) => {
    const columns = [
      { title: "Header", key: "header", dataIndex: "header", width: "30%" },

      {
        title: "Display Name",
        key: "display_name",
        dataIndex: "display_name",
        width: "40%",
        render: (text, record) => (
          <TextArea
            value={text}
            onChange={e =>
              this.onActionChange(
                index,
                record.id,
                "display_name",
                e.target.value
              )
            }
          />
        )
      },
      { title: "Status", key: "status", dataIndex: "status" },
      {
        title: "Action",
        key: "action",
        dataIndex: "action",
        width: "10%",
        render: (text, record) => (
          <Select
            style={{ width: "100%" }}
            value={text}
            onChange={value =>
              this.onActionChange(index, record.id, "action", value)
            }
          >
            <Select.Option
              disabled={record.status !== "EXIST_IN_FILE"}
              value={"add"}
            >
              Add
            </Select.Option>
            <Select.Option
              disabled={record.status !== "EXIST_IN_DB"}
              value={"keep"}
            >
              Keep
            </Select.Option>
            <Select.Option
              disabled={record.status !== "EXIST_IN_BOTH"}
              value={"override"}
            >
              Override
            </Select.Option>
            <Select.Option value={"discard"}>Discard</Select.Option>
          </Select>
        )
      }
    ];
    return (
      <div style={{ marginTop: "20px" }}>
        <div style={{ marginBottom: "20px" }}>
          <span style={{ padding: "20px", fontWeight: "bold" }}>File: </span>
          <span>{file.name}</span>
        </div>
        <Table
          columns={columns}
          dataSource={file.columns}
          rowKey={row => row.id}
        />
      </div>
    );
  };
  render() {
    const {
      uploading,
      verifying,
      fileList,
      fetched,
      successLog,
      errorLog,
      verifiedFileList,
      isVerified
    } = this.state;

    const props = {
      multiple: true,
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file]
        }));
        return false;
      },
      fileList
    };

    return (
      <div>
        <div>
          <Upload {...props}>
            <Button disabled={fetched}>
              <Icon type="upload" /> Select File
            </Button>
          </Upload>
          <Alert
            style={{ marginTop: "10px" }}
            message="Please remove commas in headers before uploading the file"
            type="warning"
          />
        </div>
        <div style={{ width: "100%" }}>
          <Button
            type="primary"
            onClick={this.handleOnClickVerify}
            disabled={fileList.length === 0 || fetched}
            loading={verifying}
            style={{ marginTop: 16, marginRight: "10px" }}
          >
            Verify
          </Button>

          <Button
            type="danger"
            disabled={!isVerified}
            onClick={() =>
              this.setState({
                verifiedFileList: [],
                fileList: [],
                uploading: false,
                verifying: false,
                fetched: false,
                isVerified: false
              })
            }
            loading={uploading}
            style={{
              marginTop: 16,
              marginLeft: "auto",
              marginRight: 0,
              margin: "10px"
            }}
          >
            Discard
          </Button>

          <Button
            type="primary"
            disabled={!isVerified}
            onClick={this.handleOnClickUpload}
            loading={uploading}
            style={{
              marginTop: 16,
              marginLeft: "auto",
              marginRight: 0,
              margin: "10px"
            }}
          >
            Upload
          </Button>
        </div>

        {errorLog.length || successLog.length ? (
          <div
            style={{ padding: 20, border: "black solid 2px", margin: "20px" }}
          >
            <h3> Log</h3>
            <div>
              <div>
                {successLog.map((entry, i) => (
                  <p style={{ color: "green" }} key={i}>
                    {entry}
                  </p>
                ))}
              </div>
              <div>
                {errorLog.map((entry, i) => (
                  <p style={{ color: "red" }} key={i}>
                    {entry}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {verifiedFileList &&
          verifiedFileList.map((file, index) => (
            <div key={index}>{this.tableView(file, index)}</div>
          ))}
      </div>
    );
  }
}

export default FileUpload;
