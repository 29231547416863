import React, { Component } from "react";

import { Layout, Collapse } from "antd";

import ProgramContactTile from "./ProgramContactTile";

class ProgramContacts extends Component {
  state = {};
  render() {
    const data = [
      {
        name: "L. Ipsum",
        title: "Senior lecturer",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcThtriGIMd9-W9rQ2q7Kfw3Cjz8U9PougOyw0YH6_NR_RjCrNgv"
      },
      {
        name: "P. Ullamcorper",
        title: "Senior lecturer",
        image:
          "https://png.pngtree.com/png-vector/20191116/ourlarge/pngtree-business-office-girl-avatar-icon-vector-download-png-image_1991055.jpg"
      },
      {
        name: "C. Tincidunt",
        title: "Lecturer",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcThtriGIMd9-W9rQ2q7Kfw3Cjz8U9PougOyw0YH6_NR_RjCrNgv"
      },
      {
        name: "M. Aliquam",
        title: "Tutor",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQsDrfjX-4hAtcz_KJN23tjCP6UhCmTxNQy68onFxCOndvXm_Jd"
      }
    ];
    return (
      <>
        <Layout
          style={{ background: "rgba(255,255,255,0.5)", padding: "20px" }}
        >
          <Collapse
            bordered={false}
            defaultActiveKey={["detail-view"]}
            className="detail-view"
          >
            <Collapse.Panel
              header={
                <div className="sub-header-text-container">
                  <span className="sub-header-text">Faculty Contacts</span>
                </div>
              }
              key="detail-view"
              style={{ background: "#a1c9eb" }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "20px",
                  flex: 1,
                  flexWrap: "wrap"
                }}
              >
                {data.map((contact, index) => (
                  <ProgramContactTile key={index} profile={contact} />
                ))}
              </div>
            </Collapse.Panel>
          </Collapse>
        </Layout>
      </>
    );
  }
}

export default ProgramContacts;
