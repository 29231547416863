import React, { Component } from "react";

import { Form, Input, Button, Drawer, Col, Row, Popconfirm } from "antd";

class CourseFrom extends Component {
  state = {};

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //this.props.form.resetFields();
        this.props.onAddStudent({ ...values }, this.props.form);
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedStudent } = this.props;

    return (
      <Drawer
        title="Create a new Student"
        width={720}
        onClose={this.onClose}
        visible={this.props.onVisibleCreateView}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="ZID">
                {getFieldDecorator("zid", {
                  initialValue: selectedStudent ? selectedStudent.zid : "",
                  rules: [{ required: true, message: "Please enter zid" }]
                })(
                  <Input
                    disabled={selectedStudent}
                    placeholder="Please enter zid"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Name">
                {getFieldDecorator("name", {
                  initialValue: selectedStudent ? selectedStudent.name : "",
                  rules: [{ required: true, message: "Please enter  name" }]
                })(<Input placeholder="Please enter  name" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Email">
                {getFieldDecorator("email", {
                  initialValue: selectedStudent ? selectedStudent.email : "",
                  rules: [{ required: true, message: "Please enter email" }]
                })(<Input placeholder="Please enter email" />)}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 16 }}>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => this.props.onCancel()}
            >
              <Button style={{ marginRight: "10px" }} type="danger">
                Cancel
              </Button>
            </Popconfirm>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

export default Form.create({ name: "register" })(CourseFrom);
